@media (max-width: 1550px) {
    .error-text-box {
        padding-top: 35px;
    }

    aside {
        width: 235px;
    }

    .right-side-cmn {
        width: calc(100% - 235px);
    }

    .table-responsive thead tr th:before {
        right: 5px;
    }

}